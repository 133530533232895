/** @jsx jsx */
import { jsx, Box, Text, Label } from 'theme-ui'
import styled from '@emotion/styled'
import { lighten } from '@theme-ui/color'
import RcCheckbox, { Props as RcCheckboxProps } from 'rc-checkbox'

const CheckboxWrapper = styled(Box)`
  & {
    .rc-checkbox {
      white-space: nowrap;
      cursor: pointer;
      outline: none;
      display: inline-block;
      position: relative;
      line-height: 1;
      vertical-align: middle;
    }
    .rc-checkbox:hover .rc-checkbox-inner,
    .rc-checkbox-input:focus + .rc-checkbox-inner {
      border-color: ${(props: any) => props.theme.colors.primary};
    }
    .rc-checkbox-inner {
      position: relative;
      top: 0;
      left: 0;
      display: inline-block;
      width: 16px;
      height: 16px;
      border-width: 1px;
      border-style: solid;
      border-radius: 3px;
      border-color: ${(props: any) => props.theme.colors.secondary};
      background-color: ${(props: any) => props.theme.colors.muted};
      transition: border-color 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55),
        background-color 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
    }
    .rc-checkbox-inner:after {
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      left: 4px;
      top: 1px;
      display: table;
      width: 4px;
      height: 7px;
      border: 2px solid;
      border-color: ${(props: any) => props.theme.colors.muted};
      border-top: 0;
      border-left: 0;
      content: ' ';
    }
    .rc-checkbox-input {
      position: absolute;
      left: 0;
      z-index: 1;
      cursor: pointer;
      opacity: 0;
      top: 0;
      bottom: 0;
      right: 0;
    }
    /* Checked state */
    .rc-checkbox-checked:hover .rc-checkbox-inner {
      border-color: ${(props: any) => props.theme.colors.primary};
    }
    .rc-checkbox-checked .rc-checkbox-inner {
      border-color: ${(props: any) => props.theme.colors.primary};
      background-color: ${(props: any) => props.theme.colors.primary};
    }
    .rc-checkbox-checked .rc-checkbox-inner:after {
      transform: rotate(45deg);
      position: absolute;
      left: 4px;
      top: 1px;
      display: table;
      width: 4px;
      height: 7px;
      border: 2px solid #ffffff;
      border-top: 0;
      border-left: 0;
      content: ' ';
    }

    .rc-checkbox-disabled.rc-checkbox-checked:hover .rc-checkbox-inner {
      border-color: ${(props: any) => props.theme.colors.secondary};
    }
    .rc-checkbox-disabled.rc-checkbox-checked .rc-checkbox-inner {
      background-color: ${(props: any) => props.theme.colors.secondary};
      border-color: ${(props: any) => props.theme.colors.secondary};
    }
    .rc-checkbox-disabled.rc-checkbox-checked .rc-checkbox-inner:after {
      animation-name: none;
      border-color: #ffffff;
    }

    .rc-checkbox-disabled:hover .rc-checkbox-inner {
      border-color: ${(props: any) => props.theme.colors.secondary};
    }
    .rc-checkbox-disabled .rc-checkbox-inner {
      border-color: ${(props: any) => props.theme.colors.secondary};
      background-color: ${(props: any) => props.theme.colors.secondary};
    }
    .rc-checkbox-disabled .rc-checkbox-inner:after {
      animation-name: none;
      border-color: ${(props: any) => props.theme.colors.secondary};
    }
    .rc-checkbox-disabled .rc-checkbox-input {
      cursor: not-allowed;
    }

    .rc-checkbox:active .rc-checkbox-inner {
      box-shadow: ${(props: any) => `${lighten(props.theme.colors.primary, 0.25)(props.theme)} 0px 0px 0px 2px;`};
    }

    @keyframes amCheckboxIn {
      0% {
        opacity: 0;
        transform-origin: 50% 50%;
        transform: scale(0, 0) rotate(45deg);
      }
      100% {
        opacity: 1;
        transform-origin: 50% 50%;
        transform: scale(1, 1) rotate(45deg);
      }
    }
    @keyframes amCheckboxOut {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
`

const Checkbox: React.FC<RcCheckboxProps> = ({ children, ...props }) => {
  return (
    <CheckboxWrapper>
      <Label
        sx={{
          display: 'inline-flex',
          width: 'auto',
          cursor: props.disabled ? 'no-drop' : 'pointer',
          userSelect: 'none',
          alignItems: 'center',
        }}
      >
        <RcCheckbox id={props.id || props.name} {...props} />
        {children && <Text sx={{ flex: 1, ml: 1 }}>{children}</Text>}
      </Label>
    </CheckboxWrapper>
  )
}

export default Checkbox
