/* eslint-disable */

import * as React from 'react'
import classNames from 'classnames'
import Icon from './icon'

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value,
      enumerable: true,
      configurable: true,
      writable: true,
    })
  } else {
    obj[key] = value
  }
  return obj
}
export default function InputIcon(props) {
  let _classNames

  const { suffixIcon } = props
  const { prefixCls } = props
  return (
    (suffixIcon &&
      (React.isValidElement(suffixIcon)
        ? React.cloneElement(suffixIcon, {
            className: classNames(
              ((_classNames = {}),
              _defineProperty(_classNames, suffixIcon.props.className, suffixIcon.props.className),
              _defineProperty(_classNames, ''.concat(prefixCls, '-picker-icon'), true),
              _classNames)
            ),
          })
        : React.createElement(
            'span',
            {
              className: ''.concat(prefixCls, '-picker-icon'),
            },
            suffixIcon
          ))) ||
    React.createElement(Icon, {
      // React.createElement('span', {
      type: 'calendar',
      className: ''.concat(prefixCls, '-picker-icon'),
    })
  )
}
// # sourceMappingURL=InputIcon.js.map
