/** @jsx jsx */
import { jsx, SxStyleProp, Box, Label, Text } from 'theme-ui'
import { Radio as LibRadio, RadioGroup } from 'react-radio-group'

export interface RadioProps extends React.HTMLProps<HTMLInputElement> {
  /**
    <p>`theme-ui` объект стилей</p>
    */
  sx?: SxStyleProp
}

export interface RadioGroupProps {
  selectedValue?: string
  onChange?: (value: string) => void
}

type Radio<P> = React.FC<P> & {
  Group?: RadioGroupProps
}

const Radio: Radio<RadioProps> = ({ sx, children, ...props }) => {
  return (
    <Box>
      <Label
        sx={{
          display: 'inline-flex',
          width: 'auto',
          position: 'relative',
          cursor: props.disabled ? 'no-drop' : 'pointer',
          userSelect: 'none',
          '&:hover > .ursip-radio-checkmark': {
            borderWidth: '3px',
          },
          '&:hover > input:disabled + .ursip-radio-checkmark': {
            // borderColor: 'border',
          },
          '& > input:checked + .ursip-radio-checkmark': {
            borderWidth: '3px',
            borderColor: 'primary',
          },

          '& > input:disabled:checked + .ursip-radio-checkmark': {
            borderColor: 'secondary',
            backgroundColor: 'secondary',
          },
          ...sx,
        }}
      >
        <LibRadio {...props} sx={{ position: 'absolute', opacity: 0, width: 0, height: 0 }} />
        <Box
          className="ursip-radio-checkmark"
          sx={{
            height: 16,
            width: 16,
            backgroundColor: 'muted',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'secondary',
            borderRadius: '50%',
            transitionProperty: 'border',
            transitionDuration: (theme) => theme.duration.normal,
          }}
        />
        {children && (
          <Text className="ursip-radio-label" sx={{ flex: 1, ml: 1 }}>
            {children}
          </Text>
        )}
      </Label>
    </Box>
  )
}

Radio.defaultProps = {}

Radio.Group = RadioGroup

export default Radio
