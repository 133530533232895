import React from 'react'
import ReactModal, { Props as ReactModalProps } from 'react-modal'

import Wrapper from './Wrapper'
import Header from './Header'
import Content from './Content'
import Footer from './Footer'

export const modalGlobalStyles = {
  '.ReactModal__Overlay': {
    transition: 'opacity 300ms ease-in-out',
    opacity: '0',
    '&--after-open': { opacity: '1' },
    '&--before-close': { opacity: '0' },
  },
  '.ReactModal__Content': {
    transition: 'all 300ms ease-in-out',
    transform: 'translateY(100px)',
    opacity: '0',
    '&--after-open': { opacity: '1', transform: 'translateY(0)' },
    '&--before-close': { opacity: '0', transform: 'translateY(100px)' },
  },
}

interface ModalProps extends React.FC<ReactModalProps> {
  Wrapper: typeof Wrapper
  Header: typeof Header
  Content: typeof Content
  Footer: typeof Footer
}

const Modal: ModalProps = (props) => {
  return (
    <ReactModal
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          backgroundColor: 'rgba(58, 58, 58, 0.5)',
          zIndex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
        },
        content: {
          position: 'static',
          background: 'transparent',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '4px',
          borderColor: 'transparent',
          outline: 'none',
          padding: 0,
        },
      }}
      {...props}
    />
  )
}

Modal.defaultProps = {
  ariaHideApp: false,
  closeTimeoutMS: 300,
}

Modal.Wrapper = Wrapper
Modal.Header = Header
Modal.Content = Content
Modal.Footer = Footer

export default Modal
