import React from 'react'
import ReactSelect, {
  components,
  Props as ReactSelectProps,
  Styles as ReactSelectStyles,
  IndicatorProps,
} from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { useThemeUI, Flex, Box } from 'theme-ui'
import { lighten } from '@theme-ui/color'
import Icon from '../Icon'

const sizesMap = {
  small: {
    height: '32px',
    fontSize: 1,
  },
  medium: {
    height: '40px',
    fontSize: 1,
  },
  large: {
    height: '48px',
    fontSize: 2,
  },
}

interface SelectProps extends ReactSelectProps {
  size?: 'small' | 'medium' | 'large'
  creatable?: boolean
}

const Select: React.FC<SelectProps> = ({ size, styles, creatable, ...props }) => {
  const { theme }: any = useThemeUI()

  const customStyles: ReactSelectStyles = {
    valueContainer: (provided) => ({ ...provided, padding: '0 14px' }),
    control: (provided) => ({
      ...provided,
      border: `1px solid ${theme.colors.secondary}`,
      backgroundColor: `${theme.colors.muted}`,
      color: `${theme.colors.text}`,
      minHeight: sizesMap[size || 'medium'].height,
      borderColor: 'none',
      boxShadow: 'none',
      borderRadius: `${theme.radii[1]}px`,
      '&:hover': {
        borderColor: `${theme.colors.primary}`,
      },
      '&:focus-within': {
        borderColor: `${theme.colors.primary}`,
        boxShadow: `${lighten(theme.colors.primary, 0.25)(theme)} 0px 0px 0px 2px;`,
      },
    }),

    option: (provided, { isDisabled, isFocused, isSelected }) => ({
      ...provided,
      cursor: isDisabled ? 'no-drop' : 'pointer',
      transition: 'background-color 0.3s',
      '&:hover': {
        backgroundColor: isSelected ? theme.colors.primary : theme.colors.muted,
      },
      '&:focus': {
        backgroundColor: theme.colors.muted,
      },
      '&:active': {
        backgroundColor: theme.colors.muted,
      },
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? `${theme.colors.primary}`
        : isFocused
        ? theme.colors.muted
        : null,
    }),

    placeholder: (provided) => ({
      ...provided,
      color: lighten(theme.colors.text, 0.5)(theme),
      fontSize: `${theme.fontSizes[sizesMap[size || 'medium'].fontSize]}px`,
    }),
    input: (provided) => ({
      ...provided,
      color: `${theme.colors.text}`,
      fontSize: `${theme.fontSizes[1]}px`,
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: '4px',
      boxShadow: '0 0px 4px #d9d9d9',
      backgroundColor: 'white',
      fontSize: `${theme.fontSizes[sizesMap[size || 'medium'].fontSize]}px`,
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 10,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      padding: '0 5px',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      cursor: 'pointer',
      padding: 0,
      color: theme.colors.secondary,
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      color: `${theme.colors.secondary}`,
    }),
  }

  const DropdownIndicator = (dropDownProps: IndicatorProps<any>) => {
    return (
      <components.DropdownIndicator {...dropDownProps}>
        <Box
          sx={{
            width: 0,
            height: 0,
            borderLeft: '5px solid transparent',
            borderRight: '5px solid transparent',
            borderTop: '6px solid',
            borderTopColor: theme.colors.secondary,
          }}
        />
      </components.DropdownIndicator>
    )
  }

  const componentProps = {
    styles: { ...customStyles, ...styles },
    components: { DropdownIndicator },
    ...props,
  }

  return creatable ? (
    <CreatableSelect
      formatCreateLabel={(name) => (
        <Flex sx={{ alignItems: 'center' }}>
          <Box>
            <Icon name="plus-circle-outline" sx={{ mr: 2, top: '1px' }} />
          </Box>
          <Box>{name}</Box>
        </Flex>
      )}
      {...componentProps}
    />
  ) : (
    <ReactSelect {...componentProps} />
  )
}

Select.defaultProps = {
  size: 'medium',
  noOptionsMessage: () => 'Не найдено',
  placeholder: 'Выбрать',
}

Select.displayName = 'Select'

export default Select
