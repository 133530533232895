/* eslint-disable */
/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'
import IMask from 'imask'
import moment from 'moment'

import DatePicker from './DatePicker'

const { RangePicker } = DatePicker

function RangeDatePicker({ sx, ...props }) {
  useEffect(() => {
    const intervalId = setInterval(() => {
      const dateInputs = document.querySelectorAll('.ursip-calendar-input')
      if (dateInputs.length) {
        clearInterval(intervalId)
        Array.prototype.forEach.call(dateInputs, (dateInput) => {
          IMask(dateInput, {
            mask: Date,
            blocks: {
              d: {
                mask: IMask.MaskedRange,
                from: 1,
                to: 31,
                maxLength: 2,
              },
              m: {
                mask: IMask.MaskedRange,
                from: 1,
                to: 12,
                maxLength: 2,
              },
              Y: {
                mask: IMask.MaskedRange,
                from: 0,
                to: 9999,
              },
            },
          })
        })
      }
    }, 100)
    return () => clearInterval(intervalId)
  })

  return <RangePicker {...props} sx={{ '& > div': { position: 'relative' }, ...sx }} />
}

RangeDatePicker.propTypes = {
  format: PropTypes.string,
  separator: PropTypes.string,
  placeholder: PropTypes.array,
}

RangeDatePicker.defaultProps = {
  format: 'DD.MM.YYYY',
  separator: '-',
  placeholder: ['Начальная дата', 'Конечная дата'],
}

RangeDatePicker.displayName = 'RangeDatePicker'

export default RangeDatePicker
