import React from 'react'
import { Box, SxStyleProp } from 'theme-ui'

interface WrapperProps {
  /**
    <p>`theme-ui` объект стилей</p>
    */
  sx?: SxStyleProp
}

const Wrapper: React.FC<WrapperProps> = ({ sx, ...props }) => (
  <Box sx={{ bg: 'white', minHeight: '100%', width: '50vw', ...sx }} {...props} />
)

export default Wrapper
