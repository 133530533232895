import RcTable from 'rc-table'
import styled from '@emotion/styled'

const prefixCls = 'ursip-table'

const StyledRcTable = styled(RcTable)`
  &.${prefixCls} {
    position: relative;
  }
  &.${prefixCls} table {
    border-spacing: 0px;
    width: 100%;
  }
  &.${prefixCls} th {
    color: ${(props: any) => props.theme.colors.text};
  }
  &.${prefixCls} th,
  &.${prefixCls} td {
    padding: 0;
    position: relative;
    border: 1px solid ${(props: any) => props.theme.colors.border};
    border-top: 0;
    border-left: 0;
    border-right: 0;
    transition: box-shadow 0.3s;
    padding: 16px 8px;
    box-sizing: border-box;
    white-space: normal;
  }
  &.${prefixCls} tr:last-child td {
    border-bottom: 0;
  }
  &.${prefixCls}-cell-fix-left, &.${prefixCls}-cell-fix-right {
    z-index: 1;
  }
  &.${prefixCls}-cell-fix-right:last-child {
    border-right-color: transparent;
  }
  &.${prefixCls}-cell-fix-left-last::after {
    pointer-events: none;
    content: '';
    transition: box-shadow 0.3s;
    position: absolute;
    top: 0;
    bottom: -1px;
    width: 20px;
    right: -1px;
    transform: translateX(100%);
  }
  &.${prefixCls}-cell-fix-right-first {
    /* box-shadow: -1px 0 0 #ff0000; */
  }
  &.${prefixCls}-cell-fix-right-first::after {
    pointer-events: none;
    content: '';
    transition: box-shadow 0.3s;
    position: absolute;
    top: 0;
    bottom: -1px;
    width: 20px;
    left: -1px;
    transform: translateX(-100%);
  }
  & .${prefixCls}-cell.${prefixCls}-cell-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &.${prefixCls}-cell.${prefixCls}-cell-ellipsis.${prefixCls}-cell-fix-left-last,
    &.${prefixCls}-cell.${prefixCls}-cell-ellipsis.${prefixCls}-cell-fix-right-first {
    overflow: visible;
  }
  &.${prefixCls}-cell.${prefixCls}-cell-ellipsis.${prefixCls}-cell-fix-left-last.${prefixCls}-cell-content,
    &.${prefixCls}-cell.${prefixCls}-cell-ellipsis.${prefixCls}-cell-fix-right-first
    .${prefixCls}-cell-content {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
  &.${prefixCls}-ping-left &.${prefixCls}-cell-fix-left-last::after {
    box-shadow: inset 10px 0 8px -8px #008000;
  }
  &.${prefixCls}-ping-right &.${prefixCls}-cell-fix-right-first::after {
    box-shadow: inset -10px 0 8px -8px #008000;
  }
  &.${prefixCls}-expand-icon-col {
    width: 60px;
  }
  &.${prefixCls}-row-expand-icon-cell {
    text-align: center;
  }
  &.${prefixCls} thead td,
  &.${prefixCls} thead th {
    padding: 8px;
    background: ${(props: any) => props.theme.colors.muted};
    text-align: left;
  }
  &.${prefixCls} thead .${prefixCls}-cell-scrollbar::after {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    left: -1px;
    width: 1px;
    background: #f7f7f7;
  }
  &.${prefixCls}-header {
    border: 1px solid #ff0000;
    border-right: 0;
    border-bottom: 0;
  }
  &.${prefixCls}-placeholder {
    text-align: center;
  }
  &.${prefixCls} tbody tr td,
  &.${prefixCls} tbody tr th {
    background: #fff;
  }
  &.${prefixCls}-content {
    border: 1px solid #ff0000;
    border-right: 0;
    border-bottom: 0;
    border-radius: 5px 0 0 0;
  }
  &.${prefixCls}-body {
    border: 1px solid #ff0000;
    border-right: 0;
    border-bottom: 0;
    border-top: 0;
  }
  &.${prefixCls}-fixed-column .${prefixCls}-body::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    border-right: 1px solid white;
    z-index: 1;
  }
  &.${prefixCls}-expanded-row .${prefixCls}-cell {
    box-shadow: inset 0 8px 8px -8px #008000;
  }
  &.${prefixCls}-expanded-row-fixed {
    box-sizing: border-box;
    margin: -16px -8px;
    padding: 16px 8px;
    margin-right: -10px;
  }
  &.${prefixCls}-expanded-row-fixed::after {
    content: '';
    position: absolute;
    width: 0;
    top: 0;
    bottom: 0;
    right: 1px;
    border-right: 1px solid white;
  }
  &.${prefixCls}-row-expand-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 1px solid currentColor;
    color: #aaa;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    line-height: 16px;
  }
  &.${prefixCls}-row-expand-icon.${prefixCls}-row-expanded::after {
    content: '-';
  }
  &.${prefixCls}-row-expand-icon.${prefixCls}-row-collapsed::after {
    content: '+';
  }
  &.${prefixCls}-row-expand-icon.${prefixCls}-row-spaced {
    visibility: hidden;
  }
  &.${prefixCls}-title {
    border: 1px solid #ff0000;
    border-bottom: 0;
    padding: 16px 8px;
  }
  &.${prefixCls}-footer {
    border: 1px solid #ff0000;
    border-top: 0;
    padding: 16px 8px;
  }
`

StyledRcTable.defaultProps = {
  prefixCls,
  emptyText: ' Нет данных',
}

export default StyledRcTable
