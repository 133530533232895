// https://theme-ui.com/theme-spec
import { datePickerGlobalStyles } from '@ursip/date-picker'
import { modalGlobalStyles } from './Modal/Modal'
import { dropdownGlobalStyles } from './Dropdown/Dropdown'
import { treeSelectGlobalStyles } from './TreeSelect/TreeSelect'

const theme = {
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  radii: [2, 4, 8],
  opacity: 0.7,
  breakpoints: ['40em', '56em', '64em'],
  fonts: {
    body: '"Open Sans", Arial, sans-serif',
    heading: '"Open Sans", Arial, sans-serif',
    monospace: 'Menlo, monospace',
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.4,
    heading: 1.125,
  },
  boxShadows: [
    `0 0 2px 0 rgba(0,0,0,.08),0 1px 4px 0 rgba(0,0,0,.16)`,
    `0 0 2px 0 rgba(0,0,0,.08),0 2px 8px 0 rgba(0,0,0,.16)`,
    `0 0 2px 0 rgba(0,0,0,.08),0 4px 16px 0 rgba(0,0,0,.16)`,
    `0 0 2px 0 rgba(0,0,0,.08),0 8px 32px 0 rgba(0,0,0,.16)`,
  ],
  zIndexes: [-999999, -1, 0, 2, 3, 4, 5, 999999],
  colors: {
    text: '#3a3a3a',
    background: '#fff',
    primary: '#59C4D9',
    secondary: '#b5b5b5',
    accent: '#609',
    muted: '#f5f5f5',

    border: '#ecebeb',
    label: '#949394',
    info: '#0091ea',
    success: '#66bb6a',
    error: '#ef5350',
  },
  duration: {
    fast: `150ms`,
    normal: `300ms`,
    slow: `450ms`,
    slowest: `600ms`,
  },
  cards: {
    primary: {
      padding: 2,
      borderRadius: 4,
      boxShadow: '0 0 8px rgba(0, 0, 0, 0.125)',
    },
    compact: {
      padding: 1,
      borderRadius: 2,
      border: '1px solid',
      borderColor: 'muted',
    },
  },
  text: {
    caps: {
      textTransform: 'uppercase',
      letterSpacing: '0.2em',
    },
    heading: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      color: 'text',
      mb: 2,
    },
  },
  styles: {
    root: {
      // body styles
      fontFamily: 'body',
      fontSize: 1,
      lineHeight: 'body',
      height: '100%',
      a: {
        color: 'primary',
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
      ...modalGlobalStyles,
      ...dropdownGlobalStyles,
      ...treeSelectGlobalStyles,
      ...datePickerGlobalStyles,
    },
  },
}

export default theme
