import ReactIcon from './ReactIcon/ReactIcon'

export function setTwoToneColor(primaryColor) {
  return ReactIcon.setTwoToneColors({
    primaryColor,
  })
}
export function getTwoToneColor() {
  const colors = ReactIcon.getTwoToneColors()
  return colors.primaryColor
}
// # sourceMappingURL=twoTonePrimaryColor.js.map
