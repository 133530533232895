/* eslint-disable */
/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'
import IMask from 'imask'
import DatePicker from './DatePicker'

const styles = {
  '& > div': { position: 'relative' },
}

function SingleDatePicker({ sx, ...props }) {
  useEffect(() => {
    const intervalId = setInterval(() => {
      const dateInput = document.querySelector('.ursip-calendar-input')
      if (dateInput) {
        clearInterval(intervalId)
        IMask(dateInput, {
          mask: Date,
          blocks: {
            d: {
              mask: IMask.MaskedRange,
              from: 1,
              to: 31,
              maxLength: 2,
            },
            m: {
              mask: IMask.MaskedRange,
              from: 1,
              to: 12,
              maxLength: 2,
            },
            Y: {
              mask: IMask.MaskedRange,
              from: 0,
              to: 9999,
            },
          },
        })
      }
    }, 100)
    return () => clearInterval(intervalId)
  })

  return <DatePicker sx={{ ...styles, ...sx }} {...props} />
}

SingleDatePicker.propTypes = {
  format: PropTypes.string,
  placeholder: PropTypes.string,
  separator: PropTypes.string,
}

SingleDatePicker.defaultProps = {
  format: 'DD.MM.YYYY',
  separator: '-',
  placeholder: 'Выберите дату',
}

SingleDatePicker.displayName = 'SingleDatePicker'

export default SingleDatePicker
