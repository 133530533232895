import React from 'react'
import { SystemStyleObject } from '@styled-system/css'
import { Button as LibButton, ButtonProps as LibButtonProps, Spinner } from 'theme-ui'
import { lighten } from '@theme-ui/color'

import Icon from '../Icon'
import { IconProps } from '../Icon/Icon'

export interface ButtonProps extends Omit<LibButtonProps, 'size' | 'type'> {
  /**
    <p>Тип кнопки</p>
    */
  type?: 'primary' | 'default' | 'dotted' | 'link'
  /**
    <p>Размер кнопки</p>
    */
  size?: 'small' | 'medium' | 'large'
  /**
    <p>Атрибут `type` кнопки</p>
    */
  htmlType?: 'submit' | 'button' | 'reset'
  /**
    <p>Атрибут `disabled` кнопки</p>
    */
  disabled?: boolean
  /**
    <p>Флаг загрузки</p>
    */
  loading?: boolean
  /**
    <p>Объект пропсов [иконки](#icon-props)</p>
    */
  icon?: IconProps
  /**
    <p>Расположение иконки внутри кнопки</p>
    */
  iconPlacement?: 'left' | 'right'
  /**
    <p>`theme-ui` объект стилей</p>
    */
  sx?: SystemStyleObject
}

const sizes = {
  small: {
    height: '32px',
    lineHeight: 1,
  },
  medium: {
    height: '40px',
  },
  large: {
    height: '48px',
    fontSize: 2,
  },
}

const Button: React.FC<ButtonProps> = ({
  type,
  children,
  loading,
  sx,
  icon,
  size,
  iconPlacement,
  htmlType,
  ...props
}) => {
  const types = {
    primary: {
      color: 'white',
      bg: 'primary',
      borderColor: 'primary',
      cursor: loading ? 'no-drop' : 'pointer',
      outline: 1,
      '&:hover': {
        opacity: (theme: any) => theme.opacity,
      },
      '&:disabled': {
        cursor: 'no-drop',
        color: 'muted',
        borderColor: 'secondary',
        backgroundColor: 'secondary',
        '&:hover': {
          opacity: '1',
          color: 'muted',
        },
      },
      '&:active': {
        boxShadow: (theme: any) => !loading && `${lighten(theme.colors.primary, 0.25)(theme)} 0px 0px 0px 2px;`,
      },
    },
    default: {
      color: 'text',
      bg: 'transparent',
      borderColor: 'secondary',
      cursor: loading ? 'no-drop' : 'pointer',
      '&:hover': {
        borderColor: loading ? 'secondary' : 'primary',
        color: loading ? 'text' : 'primary',
      },
      '&:disabled': {
        cursor: 'no-drop',
        color: 'secondary',
        backgroundColor: 'muted',
        borderColor: 'secondary',
        '&:hover': {
          opacity: '1',
        },
      },
      '&:active': {
        boxShadow: (theme: any) => !loading && `${lighten(theme.colors.primary, 0.25)(theme)} 0px 0px 0px 2px;`,
      },
    },
    dotted: {
      color: 'text',
      bg: 'transparent',
      borderStyle: 'dotted',
      borderColor: 'secondary',
      cursor: loading ? 'no-drop' : 'pointer',
      '&:hover': {
        borderColor: loading ? 'secondary' : 'primary',
        color: loading ? 'text' : 'primary',
      },
      '&:disabled': {
        cursor: 'no-drop',
        color: 'secondary',
        backgroundColor: 'muted',
        '&:hover': {
          borderColor: 'secondary',
          opacity: '1',
        },
      },
      '&:active': {
        boxShadow: (theme: any) => !loading && `${lighten(theme.colors.primary, 0.25)(theme)} 0px 0px 0px 2px;`,
      },
    },
    link: {
      color: 'primary',
      bg: 'transparent',
      cursor: loading ? 'no-drop' : 'pointer',
      '&:hover': {
        opacity: (theme: any) => (loading ? 1 : theme.opacity),
      },
      '&:disabled': {
        cursor: 'no-drop',
        color: 'secondary',
        '&:hover': {
          opacity: '1',
        },
      },
    },
  }

  return (
    <LibButton
      type={htmlType}
      sx={{
        py: 0,
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        outline: 'none',
        fontFamily: 'body',
        border: '1px solid',
        borderColor: 'transparent',
        pointerEvents: loading ? 'none' : 'auto',
        borderRadius: (theme) => `${theme.radii[1]}px`,
        pl: (icon || loading) && iconPlacement === 'left' && children ? 2 : 3,
        pr: (icon || loading) && iconPlacement === 'right' && children ? 2 : 3,
        transitionProperty: 'opacity, background, color, border-color',
        transitionDuration: (theme) => theme.duration.normal,
        opacity: (theme) => (loading ? theme.opacity : 1),
        ...sizes[size || 'medium'],
        ...types[type || 'default'],
        ...sx,
      }}
      {...props}
    >
      {loading && iconPlacement === 'left' && <Spinner mr={children ? 2 : 0} size={16} sx={{ color: 'inherit' }} />}
      {icon && !loading && iconPlacement === 'left' && (
        <Icon {...icon} sx={{ mr: children ? 2 : 0, fill: 'inherit', ...icon.sx }} />
      )}
      {children}
      {icon && !loading && iconPlacement === 'right' && <Icon {...icon} sx={{ ml: children ? 2 : 0, ...icon.sx }} />}
      {loading && iconPlacement === 'right' && <Spinner ml={children ? 2 : 0} size={16} sx={{ color: 'inherit' }} />}
    </LibButton>
  )
}

Button.defaultProps = {
  htmlType: 'button',
  type: 'default',
  size: 'medium',
  iconPlacement: 'left',
  loading: false,
  disabled: false,
}

export default Button
