/* eslint-disable */

import { lighten } from '@theme-ui/color'

export const datePickerGlobalStyles = {
  '.ursip-input': {
    color: 'text',
    fontSize: 1,
    backgroundColor: 'muted',
    borderColor: 'secondary',
    '&:hover': {
      borderColor: 'primary',
    },
    '&:focus': {
      borderColor: 'primary',
    },
    '&-disabled': {
      cursor: 'not-allowed',
      color: 'muted',
      borderColor: 'border',
      backgroundColor: 'border',
      '&:hover': {
        borderColor: 'border',
      },
    },
  },
  '.ursip-calendar-picker:focus .ursip-calendar-picker-input:not(.ursip-input-disabled)': {
    borderColor: 'primary',
  },
  '.ursip-calendar-header a:hover': {
    color: 'primary',
  },
  '.ursip-calendar-date:hover': {
    bg: 'primary',
  },
  '.ursip-calendar-today .ursip-calendar-date:hover': {
    color: 'white',
  },
  '.ursip-calendar-date:active': {
    bg: 'primary',
  },
  '.ursip-calendar-today .ursip-calendar-date': {
    color: 'primary',
    borderColor: 'primary',
  },
  '.ursip-calendar .ursip-calendar-ok-btn:hover, .ursip-calendar .ursip-calendar-ok-btn:focus': {
    backgroundColor: 'primary',
    borderColor: 'primary',
  },
  'a.ursip-calendar-month-panel-month, a.ursip-calendar-decade-panel-decade, a.ursip-calendar-month-panel-month, a.ursip-calendar-year-panel-year': {
    '&:hover': {
      bg: 'primary',
      color: 'white',
    },
  },
  '.ursip-calendar-decade-panel-selected-cell .ursip-calendar-decade-panel-decade, .ursip-calendar-year-panel-selected-cell .ursip-calendar-year-panel-year, .ursip-calendar-month-panel-selected-cell .ursip-calendar-month-panel-month': {
    bg: 'primary',
    '&:hover': {
      bg: 'primary',
    },
  },
  '.ursip-calendar-range .ursip-calendar-today :not(.ursip-calendar-disabled-cell) :not(.ursip-calendar-last-month-cell) :not(.ursip-calendar-next-month-btn-day) .ursip-calendar-date': {
    color: 'primary',
    bg: 'red',
    borderColor: 'primary',
  },
  '.ursip-calendar-range .ursip-calendar-selected-end-date .ursip-calendar-date, .ursip-calendar-range .ursip-calendar-selected-start-date .ursip-calendar-date': {
    bg: 'primary',
  },
  '.ursip-calendar-range .ursip-calendar-selected-end-date .ursip-calendar-date:hover, .ursip-calendar-range .ursip-calendar-selected-start-date .ursip-calendar-date:hover': {
    bg: 'primary',
  },
  '.ursip-calendar-range .ursip-calendar-in-range-cell:before': {
    bg: lighten('primary', 0.3),
  },
  '.ursip-calendar-picker-icon': {
    color: 'text',
  },
}

export { default as DatePicker } from './SingleDatePicker'
export { default as RangeDatePicker } from './RangeDatePicker'
