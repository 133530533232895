import React from 'react'
import { Flex, Text, SxStyleProp } from 'theme-ui'
import Icon from '../Icon'

interface HeaderProps {
  onClose?(): any
  /**
    <p>`theme-ui` объект стилей</p>
    */
  sx?: SxStyleProp
}

const Header: React.FC<HeaderProps> = ({ children, sx, onClose }) => (
  <Flex
    sx={{
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: '1px solid',
      borderColor: 'border',
      p: 2,
      ...sx,
    }}
  >
    <Text sx={{ fontSize: 2, fontWeight: 'bold' }}>{children}</Text>
    {onClose && (
      <Icon
        name="close"
        onClick={onClose}
        sx={{ cursor: 'pointer', color: 'secondary', '&:hover': { color: 'primary' } }}
      />
    )}
  </Flex>
)

export default Header
