/** @jsx jsx */
import { CSSProperties, RefObject, AriaAttributes } from 'react'

import { jsx, SxStyleProp } from 'theme-ui'
import MdIcon from '@mdi/react'

import icons from './icon-set'

export interface HTMLProps extends AriaAttributes {
  className?: string
}

export interface IconProps extends HTMLProps {
  /**
    <p>Имя иконки</p>
  */
  name: keyof typeof icons
  /**

  */
  ref?: RefObject<SVGSVGElement>
  /**
    <p>Содержимое элемента `title` внутри `SVG`.
    <br /> <a href="https://developer.mozilla.org/en-US/docs/Web/SVG/Element/title" target="_blank">Подробнее на MDN</a></p>
  */
  title?: string | null
  /**
    <p>Содержимое элемента `desc` внутри `SVG`.
    <br /> <a href="https://developer.mozilla.org/en-US/docs/Web/SVG/Element/desc" target="_blank">Подробнее на MDN</a></p>
  */
  description?: string | null
  /**
    <p>Размер в пикселях, значение будет задано параметрам `width` и `height` `SVG` элемента</p>
  */
  size?: number | string | null
  /**
    <p>Цвет иконки: задается любым доступным в CSS способом</p>
  */
  color?: string | null
  /**
    <p>Горизонтальный переворот</p>
  */
  horizontal?: boolean
  /**
    <p>Вертикальный переворот</p>
  */
  vertical?: boolean
  /**
    <p>Поворот в градусах</p>
  */
  rotate?: number
  /**
    <p>Вращение иконки: если `true`, то скорость вращения будет задана автоматически, если числовое значение, то заданное число будет являться количеством секунд, за которое иконка совершает полный оборот</p>
  */
  spin?: boolean | number
  /**
    <p>Обычный объект стилей</p>
  */
  style?: CSSProperties
  /**
    <p></p>
  */
  inStack?: boolean
  /**
    <p>`theme-ui` объект стилей</p>
  */
  sx?: SxStyleProp

  onClick?: () => void
}

const Icon: React.FC<IconProps> = ({ sx, name, ...props }) => {
  return (
    <MdIcon {...props} path={icons[name]} sx={{ fill: 'text', display: 'inline-block', position: 'relative', ...sx }} />
  )
}

Icon.defaultProps = {
  name: 'hail',
  size: 0.821, // 16x16 px
}

export default Icon
