import React from 'react'
import { Flex, SxStyleProp } from 'theme-ui'
import { Button } from '..'

interface FooterProps {
  loading?: boolean
  disabled?: boolean
  okText?: string
  cancelText?: string
  onOk?(): void
  onCancel?(): void
  /**
    <p>`theme-ui` объект стилей</p>
    */
  sx?: SxStyleProp
}

const Footer: React.FC<FooterProps> = ({ onOk, okText, cancelText, onCancel, loading, disabled, sx }) => (
  <Flex
    sx={{
      p: 2,
      justifyContent: 'flex-end',
      alignItems: 'center',
      borderTop: '1px solid',
      borderColor: 'border',
      ...sx,
    }}
  >
    <Button type="primary" htmlType="submit" disabled={disabled} loading={loading} onClick={onOk}>
      {okText}
    </Button>
    <Button
      sx={{
        ml: 2,
      }}
      onClick={onCancel}
    >
      {cancelText}
    </Button>
  </Flex>
)

Footer.defaultProps = {
  loading: false,
  disabled: false,
  okText: 'Сохранить',
  cancelText: 'Отменить',
  onOk: () => null,
  onCancel: () => null,
}

export default Footer
