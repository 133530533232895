/** @jsx jsx */
import { jsx, SxStyleProp } from 'theme-ui'
import InputMask, { Props as InputMaskProps } from 'react-input-mask'
import { Flex, Box } from '@theme-ui/components'
import { lighten } from '@theme-ui/color'

export interface InputProps extends Omit<InputMaskProps, 'size' | 'prefix' | 'mask' | 'inputRef'> {
  /**
    <p>Размер поля</p>
    */
  size?: 'small' | 'medium' | 'large'
  /**
    <p>Состояние инпута</p>
    */
  state?: 'normal' | 'error' | 'success'
  /**
    <p>Реакт элемент, который будет вставлен в начало поля</p>
    */
  prefix?: React.ReactNode
  /**
    <p>Реакт элемент, который будет вставлен в конец поля</p>
    */
  suffix?: React.ReactNode
  /**
    <p>Маска
    <br /> <a href="https://www.npmjs.com/package/react-input-mask" target="_blank">Подробнее на npmjs.com</a></p>
    */
  mask?: string | Array<string | RegExp>
  /**
    <p>Атрибут `readonly` поля</p>
    */
  readonly?: boolean
  /**
    <p>Атрибут `disabled` поля</p>
    */
  disabled?: boolean
  /**
    <p>`theme-ui` объект стилей</p>
    */
  sx?: SxStyleProp
  /**
   * Используйте inputRef вместо ref, если нужно получить ноду для управления фокусом и т.д.
   */
  inputRef?: React.Ref<HTMLInputElement>
}

const defaultSize = 'medium'
const defaultState = 'normal'
const defaultMask = [/\W/g] // allow all chars and lengths
const boxShadowPosition = '0px 0px 0px 2px'
const lightenValue = 0.25

const Input: React.FC<InputProps> = ({
  disabled,
  prefix,
  suffix,
  size = defaultSize,
  mask = defaultMask,
  state = defaultState,
  readonly,
  sx,
  inputRef,
  ...props
}) => {
  const sizesMap = {
    small: {
      height: '32px',
      fontSize: 1,
    },
    medium: {
      height: '40px',
      fontSize: 1,
    },
    large: {
      height: '48px',
      fontSize: 2,
    },
  }

  const statesMap = {
    normal: {
      borderColor: 'secondary',
      '& svg': {
        color: 'text',
      },
    },
    error: {
      borderColor: 'error',
      '& svg': {
        color: 'error',
      },
      '&:hover': {
        borderColor: 'error',
      },
      '&:focus-within': {
        borderColor: 'error',
        boxShadow: (theme: any) => `${lighten(theme.colors.error, lightenValue)(theme)} ${boxShadowPosition};`,
      },
    },
    success: {
      borderColor: 'success',
      '& svg': {
        color: 'success',
      },
      '&:hover': {
        borderColor: 'success',
      },
      '&:focus-within': {
        borderColor: 'success',
        boxShadow: (theme: any) => `${lighten(theme.colors.success, lightenValue)(theme)} ${boxShadowPosition};`,
      },
    },
  }

  return (
    <Flex
      data-disabled={disabled}
      sx={{
        display: props.type === 'hidden' ? 'none' : 'flex',
        color: 'text',
        alignItems: 'center',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: (theme) => theme.radii[1],
        backgroundColor: 'muted',
        transitionDuration: (theme) => theme.duration.normal,
        transitionProperty: 'color, border-color, background-color',
        overflow: 'hidden',
        '&:hover': {
          borderColor: disabled ? 'secondary' : 'primary',
        },
        '&:focus-within': {
          borderColor: 'primary',
          backgroundColor: 'background',
          boxShadow: (theme) => `${lighten(theme.colors.primary, lightenValue)(theme)} ${boxShadowPosition};`,
        },
        '&[data-disabled="true"]': {
          color: 'text',
          borderColor: 'border',
          backgroundColor: 'border',
          '& svg': {
            color: 'muted',
          },
        },

        ...sizesMap[size],
        ...statesMap[state],
        ...sx,
      }}
    >
      {prefix && (
        <Box
          sx={{
            pl: 2,
            position: 'relative',
            color: disabled ? 'muted' : 'inherit',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {prefix}
        </Box>
      )}
      <InputMask
        mask={!readonly ? mask : ''}
        readOnly={readonly}
        disabled={disabled}
        inputRef={inputRef}
        sx={{
          display: 'block',
          flex: 1,
          height: 'inherit',
          outline: 'none',
          border: 'none',
          fontFamily: 'body',
          fontSize: 'inherit',
          color: 'inherit',
          pl: prefix ? 2 : 3,
          pr: suffix ? 2 : 3,
          backgroundColor: 'inherit',
          '&:disabled': {
            cursor: 'no-drop',
          },
          '::placeholder': {
            color: lighten('text', 0.5),
          },
        }}
        {...props}
      />

      {suffix && (
        <Box
          sx={{
            pr: 2,
            position: 'relative',
            color: disabled ? 'muted' : 'inherit',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {suffix}
        </Box>
      )}
    </Flex>
  )
}

Input.defaultProps = {
  type: 'text',
  mask: defaultMask,
  size: defaultSize,
  state: defaultState,
  alwaysShowMask: false,
  maskChar: '_',
  readonly: false,
  disabled: false,
  formatChars: {
    '9': '[0-9]',
    a: '[A-Za-z]',
    '*': '[A-Za-zА-Яа-я0-9]',
  },
}

export default Input
