import 'react-tippy/dist/tippy.css'

import React from 'react'
import { Tooltip as Tippy, TooltipProps } from 'react-tippy'
import { Text } from 'theme-ui'

const Tooltip: React.FC<TooltipProps> = ({ title, style, ...props }) => {
  const html = <Text sx={{ fontSize: 0 }}>{title}</Text>

  return <Tippy html={html} style={{ lineHeight: 0, ...style }} {...props} />
}

Tooltip.defaultProps = {
  arrow: true,
}

export default Tooltip
