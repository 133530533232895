import React from 'react'
import { Box, Spinner, SxStyleProp } from 'theme-ui'

interface ContentProps {
  loading?: boolean
  /**
    <p>`theme-ui` объект стилей</p>
    */
  sx?: SxStyleProp
}

const Content: React.FC<ContentProps> = ({ sx, loading, children, ...props }) => (
  <Box
    sx={{
      p: 3,
      position: 'relative',
      minHeight: '74px',
      maxHeight: '60vh',
      overflow: 'auto',
      ...sx,
    }}
    {...props}
  >
    {loading && (
      <Box
        sx={{ position: 'fixed', top: '50%', left: '50%', zIndex: 1, transform: 'translateX(-50%) translateY(-50%)' }}
      >
        <Spinner />
      </Box>
    )}
    {children}
  </Box>
)

export default Content
