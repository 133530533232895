import { Tab as UnstyledTab, Tabs, TabList as UnstyledTabList, TabPanel } from 'react-tabs'
import styled from '@emotion/styled'

const TabList: any = styled(UnstyledTabList)`
  display: flex;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  color: ${(props: any) => props.theme.colors.border};
  padding: 0;
  margin: 0;
`

const Tab: any = styled(UnstyledTab)`
  & {
    padding: 8px 16px;
    text-decoration: none;
    color: ${(props: any) => (props.disabled ? props.theme.colors.secondary : props.theme.colors.text)};
    background-color: Transparent;
    box-sizing: border-box;
    background-repeat: no-repeat;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: transparent;
    cursor: ${(props: any) => (props.disabled ? 'no-drop' : 'pointer')};
    overflow: hidden;
    outline: none;
    font-size: 13px;
    white-space: nowrap;
    margin-bottom: -1px;
    transition-duration: ${(props: any) => props.theme.duration.normal};
    transition-property: color, border-color, background-color;
    &:hover {
      border-bottom-style: solid;
      border-bottom-width: 1px;
      border-color: ${(props: any) => (props.disabled ? 'transparent' : props.theme.colors.primary)};
      box-sizing: border-box;
      color: ${(props: any) => (props.disabled ? props.theme.colors.secondary : props.theme.colors.primary)};
    }
    &.react-tabs__tab--selected {
      border-color: ${(props: any) => props.theme.colors.primary};
      margin-bottom: -1px;
      box-sizing: border-box;
      color: ${(props: any) => props.theme.colors.primary};
    }
  }
`

Tab.tabsRole = 'Tab'
TabList.tabsRole = 'TabList'

export default { Tabs, TabPanel, Tab, TabList }
