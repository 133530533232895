import React from 'react'
import RcDropdown from 'rc-dropdown'
import { DropdownProps } from 'rc-dropdown/lib/Dropdown'

export const dropdownGlobalStyles = {
  '.rc-dropdown': {
    position: 'absolute',
    left: '-9999px',
    top: '-9999px',
    zIndex: '1070',
    display: 'block',
    fontFamily: 'body',
    fontSize: 0,
    fontWeight: 'normal',
    lineHeight: '1.5',
  },
  '.rc-dropdown-hidden': { display: 'none' },
  '.rc-dropdown-menu': {
    outline: 'none',
    position: 'relative',
    listStyleType: 'none',
    padding: '0',
    margin: '2px 0 2px',
    textAlign: 'left',
    backgroundColor: '#fff',
    borderRadius: '3px',
    boxShadow: '0 1px 5px #ccc',
    backgroundClip: 'padding-box',
    border: '1px solid #ccc',
  },
  '.rc-dropdown-menu > li': { margin: '0', padding: '0' },
  '.rc-dropdown-menu:before': {
    content: '""',
    position: 'absolute',
    top: '-4px',
    left: '0',
    width: '100%',
    height: '4px',
    background: ['#ffffff', 'rgba(255, 255, 255, 0.01)'],
  },
  '.rc-dropdown-menu > .rc-dropdown-menu-item': {
    position: 'relative',
    display: 'block',
    px: 3,
    py: 2,
    clear: 'both',
    color: 'text',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
  },
  '.rc-dropdown-menu > .rc-dropdown-menu-item:hover,\n.rc-dropdown-menu > .rc-dropdown-menu-item-active,\n.rc-dropdown-menu > .rc-dropdown-menu-item-selected': {
    backgroundColor: 'muted',
  },
  '.rc-dropdown-menu > .rc-dropdown-menu-item-selected': {
    position: 'relative',
  },
  '.rc-dropdown-menu > .rc-dropdown-menu-item-disabled': {
    color: 'secondary',
    cursor: 'no-drop',
    pointerEvents: 'none',
  },
  '.rc-dropdown-menu > .rc-dropdown-menu-item-disabled:hover': {
    color: '#ccc',
    backgroundColor: '#fff',
    cursor: 'no-drop',
  },
  '.rc-dropdown-menu > .rc-dropdown-menu-item:last-child': {
    borderBottomLeftRadius: '3px',
    borderBottomRightRadius: '3px',
  },
  '.rc-dropdown-menu > .rc-dropdown-menu-item:first-child': {
    borderTopLeftRadius: '3px',
    borderTopRightRadius: '3px',
  },
  '.rc-dropdown-menu > .rc-dropdown-menu-item-divider': {
    height: '1px',
    margin: '1px 0',
    overflow: 'hidden',
    backgroundColor: '#e5e5e5',
    lineHeight: '0',
  },
  '.rc-dropdown-slide-up-enter,\n.rc-dropdown-slide-up-appear': {
    animationDuration: (theme: any) => theme.duration.normal,
    animationFillMode: 'both',
    transformOrigin: '0 0',
    display: 'block !important',
    opacity: '0',
    animationTimingFunction: 'cubic-bezier(0.08, 0.82, 0.17, 1)',
    animationPlayState: 'paused',
  },
  '.rc-dropdown-slide-up-leave': {
    animationDuration: (theme: any) => theme.duration.normal,
    animationFillMode: 'both',
    transformOrigin: '0 0',
    display: 'block !important',
    opacity: '1',
    animationTimingFunction: 'cubic-bezier(0.6, 0.04, 0.98, 0.34)',
    animationPlayState: 'paused',
  },
  '.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-bottomLeft,\n.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-bottomLeft,\n.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-bottomCenter,\n.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-bottomCenter,\n.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-bottomRight,\n.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-bottomRight': {
    animationName: 'rcDropdownSlideUpIn',
    animationPlayState: 'running',
  },
  '.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-topLeft,\n.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-topLeft,\n.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-topCenter,\n.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-topCenter,\n.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-topRight,\n.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-topRight': {
    animationName: 'rcDropdownSlideDownIn',
    animationPlayState: 'running',
  },
  '.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-bottomLeft,\n.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-bottomCenter,\n.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-bottomRight': {
    animationName: 'rcDropdownSlideUpOut',
    animationPlayState: 'running',
  },
  '.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-topLeft,\n.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-topCenter,\n.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-topRight': {
    animationName: 'rcDropdownSlideDownOut',
    animationPlayState: 'running',
  },
  '@keyframes rcDropdownSlideUpIn': {
    '0%': { opacity: '0', transformOrigin: '0% 0%', transform: 'scaleY(0)' },
    '100%': { opacity: '1', transformOrigin: '0% 0%', transform: 'scaleY(1)' },
  },
  '@keyframes rcDropdownSlideUpOut': {
    '0%': { opacity: '1', transformOrigin: '0% 0%', transform: 'scaleY(1)' },
    '100%': { opacity: '0', transformOrigin: '0% 0%', transform: 'scaleY(0)' },
  },
  '@keyframes rcDropdownSlideDownIn': {
    '0%': { opacity: '0', transformOrigin: '0% 100%', transform: 'scaleY(0)' },
    '100%': { opacity: '1', transformOrigin: '0% 100%', transform: 'scaleY(1)' },
  },
  '@keyframes rcDropdownSlideDownOut': {
    '0%': { opacity: '1', transformOrigin: '0% 100%', transform: 'scaleY(1)' },
    '100%': { opacity: '0', transformOrigin: '0% 100%', transform: 'scaleY(0)' },
  },
  '.rc-dropdown-arrow': {
    position: 'absolute',
    borderWidth: '4px',
    borderColor: 'transparent',
    boxShadow: '0 1px 5px #ccc',
    borderStyle: 'solid',
    transform: 'rotate(45deg)',
  },
  '.rc-dropdown-show-arrow.rc-dropdown-placement-top,\n.rc-dropdown-show-arrow.rc-dropdown-placement-topLeft,\n.rc-dropdown-show-arrow.rc-dropdown-placement-topRight': {
    paddingBottom: '6px',
  },
  '.rc-dropdown-show-arrow.rc-dropdown-placement-bottom,\n.rc-dropdown-show-arrow.rc-dropdown-placement-bottomLeft,\n.rc-dropdown-show-arrow.rc-dropdown-placement-bottomRight': {
    paddingTop: '6px',
  },
  '.rc-dropdown-placement-top .rc-dropdown-arrow,\n.rc-dropdown-placement-topLeft .rc-dropdown-arrow,\n.rc-dropdown-placement-topRight .rc-dropdown-arrow': {
    bottom: '4px',
    borderTopColor: 'white',
  },
  '.rc-dropdown-placement-top .rc-dropdown-arrow': { left: '50%' },
  '.rc-dropdown-placement-topLeft .rc-dropdown-arrow': { left: '15%' },
  '.rc-dropdown-placement-topRight .rc-dropdown-arrow': { right: '15%' },
  '.rc-dropdown-placement-bottom .rc-dropdown-arrow,\n.rc-dropdown-placement-bottomLeft .rc-dropdown-arrow,\n.rc-dropdown-placement-bottomRight .rc-dropdown-arrow': {
    top: '4px',
    borderBottomColor: 'white',
  },
  '.rc-dropdown-placement-bottom .rc-dropdown-arrow': { left: '50%' },
  '.rc-dropdown-placement-bottomLeft .rc-dropdown-arrow': { left: '15%' },
  '.rc-dropdown-placement-bottomRight .rc-dropdown-arrow': { right: '15%' },
}

const Dropdown: React.FC<DropdownProps> = (props) => {
  return <RcDropdown animation="slide-up" {...props} />
}

export default Dropdown
