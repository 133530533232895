// All icons available here https://materialdesignicons.com/cdn/2.0.46/

import {
  mdiHail,
  mdiFolder,
  mdiFolderOpen,
  mdiChevronDown,
  mdiChevronUp,
  mdiChevronLeft,
  mdiChevronRight,
  mdiMagnify,
  mdiLinkVariant,
  mdiConsoleNetworkOutline,
  mdiArrowLeft,
  mdiArrowRight,
  mdiAccount,
  mdiAccountCircle,
  mdiClose,
  mdiInformationOutline,
  mdiArchive,
  mdiClock,
  mdiCheck,
  mdiPlusCircleOutline,
  mdiDotsHorizontal,
  mdiPencil,
  mdiDelete,
  mdiMenu,
  mdiHome,
  mdiFilter,
  mdiArchiveArrowDown,
  mdiArchiveArrowUp,
  mdiPlusBox,
  mdiMinusBox,
  mdiAlertCircle,
  mdiShieldAlertOutline,
  mdiArrowDownBoldBox,
  mdiCircle,
  mdiCog,
  mdiLoading,
  mdiPlus,
} from '@mdi/js'

const iconSet = {
  hail: mdiHail,
  folder: mdiFolder,
  'folder-open': mdiFolderOpen,
  'chevron-left': mdiChevronLeft,
  'chevron-right': mdiChevronRight,
  'plus-circle-outline': mdiPlusCircleOutline,
  pencil: mdiPencil,
  delete: mdiDelete,
  'information-outline': mdiInformationOutline,
  'chevron-down': mdiChevronDown,
  'chevron-up': mdiChevronUp,
  account: mdiAccount,
  'account-circle': mdiAccountCircle,
  close: mdiClose,
  check: mdiCheck,
  'dots-horizontal': mdiDotsHorizontal,
  magnify: mdiMagnify,
  'link-variant': mdiLinkVariant,
  'console-network-outline': mdiConsoleNetworkOutline,
  'arrow-left': mdiArrowLeft,
  'arrow-right': mdiArrowRight,
  archive: mdiArchive,
  clock: mdiClock,
  menu: mdiMenu,
  home: mdiHome,
  filter: mdiFilter,
  'archive-arrow-down': mdiArchiveArrowDown,
  'archive-arrow-up': mdiArchiveArrowUp,
  'plus-box': mdiPlusBox,
  'minus-box': mdiMinusBox,
  'alert-circle': mdiAlertCircle,
  'shield-alert-outline': mdiShieldAlertOutline,
  'arrow-down-bold-box': mdiArrowDownBoldBox,
  circle: mdiCircle,
  cog: mdiCog,
  loading: mdiLoading,
  plus: mdiPlus,
}

export default iconSet
