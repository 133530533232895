/* eslint-disable */

export const __esModule = true

import _react from 'react'
import _propTypes from 'prop-types'

var _propTypes2 = _interopRequireDefault(_propTypes)

import _gud from 'gud'

var _gud2 = _interopRequireDefault(_gud)

import _warning from 'warning'

var _warning2 = _interopRequireDefault(_warning)

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj }
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function')
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called")
  }
  return call && (typeof call === 'object' || typeof call === 'function') ? call : self
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError('Super expression must either be null or a function, not ' + typeof superClass)
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true,
    },
  })
  if (superClass)
    Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : (subClass.__proto__ = superClass)
}

var MAX_SIGNED_31_BIT_INT = 1073741823

// Inlined Object.is polyfill.
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/is
function objectIs(x, y) {
  if (x === y) {
    return x !== 0 || 1 / x === 1 / y
  } else {
    return x !== x && y !== y
  }
}

function createEventEmitter(value) {
  var handlers = []
  return {
    on: function on(handler) {
      handlers.push(handler)
    },
    off: function off(handler) {
      handlers = handlers.filter(function(h) {
        return h !== handler
      })
    },
    get: function get() {
      return value
    },
    set: function set(newValue, changedBits) {
      value = newValue
      handlers.forEach(function(handler) {
        return handler(value, changedBits)
      })
    },
  }
}

function onlyChild(children) {
  return Array.isArray(children) ? children[0] : children
}

function createReactContext(defaultValue, calculateChangedBits) {
  var _Provider$childContex, _Consumer$contextType

  var contextProp = '__create-react-context-' + (0, _gud2.default)() + '__'

  var Provider = (function(_Component) {
    _inherits(Provider, _Component)

    function Provider() {
      var _temp, _this, _ret

      _classCallCheck(this, Provider)

      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key]
      }

      return (
        (_ret =
          ((_temp =
            ((_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args)))),
            _this)),
          (_this.emitter = createEventEmitter(_this.props.value)),
          _temp)),
        _possibleConstructorReturn(_this, _ret)
      )
    }

    Provider.prototype.getChildContext = function getChildContext() {
      var _ref

      return (_ref = {}), (_ref[contextProp] = this.emitter), _ref
    }

    Provider.prototype.componentWillReceiveProps = function componentWillReceiveProps(nextProps) {
      if (this.props.value !== nextProps.value) {
        var oldValue = this.props.value
        var newValue = nextProps.value
        var changedBits = void 0

        if (objectIs(oldValue, newValue)) {
          changedBits = 0 // No change
        } else {
          changedBits =
            typeof calculateChangedBits === 'function'
              ? calculateChangedBits(oldValue, newValue)
              : MAX_SIGNED_31_BIT_INT
          if (process.env.NODE_ENV !== 'production') {
            ;(0, _warning2.default)(
              (changedBits & MAX_SIGNED_31_BIT_INT) === changedBits,
              'calculateChangedBits: Expected the return value to be a ' + '31-bit integer. Instead received: %s',
              changedBits
            )
          }

          changedBits |= 0

          if (changedBits !== 0) {
            this.emitter.set(nextProps.value, changedBits)
          }
        }
      }
    }

    Provider.prototype.render = function render() {
      return this.props.children
    }

    return Provider
  })(_react.Component)

  Provider.childContextTypes =
    ((_Provider$childContex = {}),
    (_Provider$childContex[contextProp] = _propTypes2.default.object.isRequired),
    _Provider$childContex)

  var Consumer = (function(_Component2) {
    _inherits(Consumer, _Component2)

    function Consumer() {
      var _temp2, _this2, _ret2

      _classCallCheck(this, Consumer)

      for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2]
      }

      return (
        (_ret2 =
          ((_temp2 =
            ((_this2 = _possibleConstructorReturn(this, _Component2.call.apply(_Component2, [this].concat(args)))),
            _this2)),
          (_this2.state = {
            value: _this2.getValue(),
          }),
          (_this2.onUpdate = function(newValue, changedBits) {
            var observedBits = _this2.observedBits | 0
            if ((observedBits & changedBits) !== 0) {
              _this2.setState({ value: _this2.getValue() })
            }
          }),
          _temp2)),
        _possibleConstructorReturn(_this2, _ret2)
      )
    }

    Consumer.prototype.componentWillReceiveProps = function componentWillReceiveProps(nextProps) {
      var observedBits = nextProps.observedBits

      this.observedBits =
        observedBits === undefined || observedBits === null
          ? MAX_SIGNED_31_BIT_INT // Subscribe to all changes by default
          : observedBits
    }

    Consumer.prototype.componentDidMount = function componentDidMount() {
      if (this.context[contextProp]) {
        this.context[contextProp].on(this.onUpdate)
      }
      var observedBits = this.props.observedBits

      this.observedBits =
        observedBits === undefined || observedBits === null
          ? MAX_SIGNED_31_BIT_INT // Subscribe to all changes by default
          : observedBits
    }

    Consumer.prototype.componentWillUnmount = function componentWillUnmount() {
      if (this.context[contextProp]) {
        this.context[contextProp].off(this.onUpdate)
      }
    }

    Consumer.prototype.getValue = function getValue() {
      if (this.context[contextProp]) {
        return this.context[contextProp].get()
      } else {
        return defaultValue
      }
    }

    Consumer.prototype.render = function render() {
      return onlyChild(this.props.children)(this.state.value)
    }

    return Consumer
  })(_react.Component)

  Consumer.contextTypes =
    ((_Consumer$contextType = {}),
    (_Consumer$contextType[contextProp] = _propTypes2.default.object),
    _Consumer$contextType)

  return {
    Provider: Provider,
    Consumer: Consumer,
  }
}

export default createReactContext
// export default exports['default'];
